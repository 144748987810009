<template>
  <div>
    <div class="vx-row w-full">
      <div class="vx-col lg:w-2/3 w-full mb-base">
        <form :v-model="seal" autocomplete="nofill">
          <shipblu-card :cardLoading="sealLoading" class="p-5">
            <h3 class="mb-4">{{$t('Master Bag Information')}}</h3>
            <div class="vx-row">
              <div class="vx-col w-1/3 mt-4">
                <label class="font-semibold">{{$t('Seal No.')}}</label>
                <h5>{{seal.code}}</h5>
              </div>
              <div class="vx-col w-1/3 mt-4">
                <label class="font-semibold">{{$t('Created On')}}</label>
                <h5>{{seal.created ? new Date(seal.created).toLocaleDateString('fr-CA') : ''}}</h5>
              </div>
              <div class="vx-col w-1/3 mt-4">
                <label class="font-semibold">{{$t('Packages In-Bag')}}</label>
                <h5>{{totalRows}}</h5>
              </div>
              <div class="vx-col w-1/3 mt-4">
                <label class="font-semibold">{{$t('From Warehouse')}}</label>
                <h5>{{warehousesDic[seal.bin.warehouse]}}</h5>
              </div>
              <div class="vx-col w-1/2 mt-4">
                <label class="font-semibold">{{$t('To warehouse')}}</label>
                <h5>{{warehousesDic[seal.bin.to_warehouse]}}</h5>
              </div>
              <div class="vx-col w-1/3 mt-4">
                <label class="font-semibold">{{$t('Current warehouse')}}</label>
                <h5>{{warehousesDic[seal.current_warehouse]}}</h5>
              </div>
              <div class="vx-col w-1/2 mt-4">
                <label class="font-semibold">{{$t('Bag Type')}}</label>
                <h5>{{seal.fragile ? 'Fragile' : 'Normal'}}</h5>
              </div>
              <div class="vx-col w-1/3 mt-4">
                <vs-chip class="" :color="getOrderStatusColor(seal)">{{ seal.status }}</vs-chip>
              </div>
            </div>
            <div v-if="$route.params.warehouseID === warehousesDic[seal.bin.warehouse] && seal.status === 'created'" class="flex flex-wrap items-center justify-end mt-6">
              <vs-button class="ml-auto mt-2" @click="confirmSeal('sealed')" color="success">{{$t('Seal')}}</vs-button>
            </div>
          </shipblu-card>
        </form>
      </div>
      <div class="vx-col lg:w-1/3 w-full">
        <shipblu-card :cardLoading="trackingsLoading" class="mb-base p-5">
          <h3 class="mb-4">{{$t('Tracking')}}</h3>
          <div v-if="localStorage.getItem('multipleWarehouses') === 'true'" class="w-full mb-base">
            <form class="w-full" :v-model="tracking">
              <v-select class="w-full" name="tracking" v-model="status" :placeholder="$t('Tracking Event')" v-validate="'required'" label="name" :options="sealTrackingEvents"/>
              <span class="text-danger text-sm" v-show="errors.has('tracking')">{{ errors.first('tracking') }}</span>
            </form>
            <div class="flex flex-wrap items-center justify-end mt-6">
              <vs-button type="filled" color="success" @click="addTracking" class="float-right block">{{$t('Add')}}</vs-button>
            </div>
          </div>
          <div v-for="(item, index) in trackings" :key="index">
            <div class="rounded-lg">
              <ul class="vx-timeline h-20 w-full mb-0 xs:mb-32 sm:mb-0">
                <li>
                  <div class="timeline-icon h-2 w-2 inline-block rounded-full mr-2 bg-grey"></div>
                  <div class="vx-row flex">
                    <div class="timeline-info vx-col w-1/3 sm:w-1/3 xs:w-full xs:mb-1">
                      <small class="activity-e-status block">{{$t("Status")}}</small>
                      <vs-chip
                        :color="getOrderStatusColor(item)"
                        class="break-all product-order-status">
                          {{ $t(getStatusLabel(item)) }}
                      </vs-chip>
                    </div>
                    <div class="p-0 vx-col xl:pl-0 lg:w-1/3 lg:pr-2 sm:w-1/3 sm:text-left xs:text-left xs:w-full xs:mb-1">
                      <small class="activity-e-time">{{$t("Name")}}</small>
                      <p class="font-semibold lg:text-sm mr-2">{{item.user ? item.user.first_name : '' }} {{item.user ? item.user.last_name  : '' }}</p>
                    </div>
                    <div class="p-0 vx-col xl:w-1/3 lg:w-1/4 sm:w-1/3 sm:text-left xs:text-left xs:w-full xs:mb-1">
                      <small class="activity-e-time">{{$t("Time")}}</small>
                      <p class="font-semibold lg:text-sm">{{ new Date(item.created).toLocaleDateString('fr-CA') }} {{ new Date(item.created).toLocaleTimeString('en-GB') }}</p>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </shipblu-card>
      </div>
    </div>
    <shipblu-table
      :data="sealOrders"
      orders
      :tableLoader="tableLoader"
      :hideHeader="true"
    >
      <template slot="thead">
        <shipblu-th>{{$t('Tracking No.')}}</shipblu-th>
        <shipblu-th>{{$t('Package ID')}}</shipblu-th>
        <shipblu-th>{{$t('Fragile')}}</shipblu-th>
        <shipblu-th>{{$t('Merchant Name')}}</shipblu-th>
        <shipblu-th>{{$t('Pickup Date')}}</shipblu-th>
        <shipblu-th>{{$t('Scheduled Delivery Date')}}</shipblu-th>
        <shipblu-th>{{$t('Status')}}</shipblu-th>
      </template>

      <template slot-scope="{ data }">
        <shipblu-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data" :color="`${getStyle(data[indextr].order)}`">
          <shipblu-td class="sm:hidden block col-span-2 p-2 order-3">
            <!-- line -->
            <p style="background-color: #DCDCDC;height: 1px;"></p>
          </shipblu-td>

          <shipblu-td :data="data[indextr].tracking_number" class="text-left sm:order-3 order-1 sm:pointer-events-none">
            <div class="flex sm:static absolute top-3 gap-2 items-start justify-start transform-50" v-on:click.stop>
              <p class="sm:text-base text-lg flex gap-1 items-start justify-center">
                {{ data[indextr].order.tracking_number }}
                <span @click="copied(data[indextr].order.tracking_number)" class="material-icons-round text-grey hover:text-primary text-xl cursor-pointer sm:hidden block">content_copy</span>
              </p>
            </div>
          </shipblu-td> 
          
          <shipblu-td class="order-4" :data="data[indextr].package">
            <p class="sm:hidden block text-secondary text-sm mb-2">{{$t('Package ID')}}</p>
            <p class="sm:text-base text-sm">
              {{ Array(String(data[indextr].package.id).length - 1).join("*") + String(data[indextr].package.id).slice(-2) }} <br/>
            </p>
          </shipblu-td>

          <shipblu-td class="order-5" :data="data[indextr].fragile">
            <p class="sm:hidden block text-secondary text-sm mb-2">{{$t('Fragile')}}</p>
            <p class="sm:text-base text-sm">
              {{ data[indextr].package.fragile }}
            </p>
          </shipblu-td>

          <shipblu-td class="order-6" :data="data[indextr].merchantName">
            <p class="sm:hidden block text-secondary text-sm mb-2">{{$t('Merchant Name')}}</p>
            <p class="sm:text-base text-sm">
              {{ data[indextr].order.merchant.name }}
            </p>
          </shipblu-td>

          <shipblu-td class="order-7" :data="data[indextr].deliveryTime">
            <p class="sm:hidden block text-secondary text-sm mb-2">{{$t('Pickup Date')}}</p>
            <p class="sm:text-base text-sm">
              {{ new Date(data[indextr].order.pickup_date).toLocaleDateString('fr-CA')}}
            </p>
          </shipblu-td>

          <shipblu-td class="order-8" :data="data[indextr].deliveryTime">
            <p class="sm:hidden block text-secondary text-sm mb-2">{{$t('Scheduled Delivery Date')}}</p>
            <p class="sm:text-base text-sm">
            {{ data[indextr].order.estimated_date }}
            </p>
          </shipblu-td>

          <shipblu-td class="order-9" :data="data[indextr].status" :style="`color: ${getStyle(data[indextr].order)}`" style="white-space: nowrap;">
            <p class="sm:hidden block text-secondary text-sm mb-2">{{$t('Status')}}</p>
            <div class="flex" style="width: fit-content;">
              <span class="capitalize border border-solid rounded-4xl px-5 py-1.5 leading-none sm:text-sm text-xs text-white" :style="`background-color: ${getStyle(data[indextr].order)}`">{{ $t(getStatusLabel(data[indextr].order)) }}</span>
            </div>
          </shipblu-td>
        </shipblu-tr>
      </template>
    </shipblu-table>
    <shipblu-pagination :maximumItems="maximumItems" :noOptions="true" :totalRows="totalRows" :currentPage="currentPage" @currentPage="currentPage = $event" />
    <template v-if="seal.status !== 'completed' && $route.params.warehouseID === warehousesDic[seal.bin.to_warehouse]">
      <vs-button class="float-right m-5 block" color="warning" @click="confirmSeal('missing_packages')" :disabled="(seal.status !== 'dropped_off' || seal.status === 'completed' || seal.status === 'missing_packages') || seal.is_completed"  size="medium">{{$t('Missing Packages')}}</vs-button>
      <vs-button class="float-right m-5 block" color="success" @click="updateSealTracking('completed')" :disabled="(seal.status !== 'dropped_off' || seal.status === 'completed' || seal.status === 'missing_packages') || !seal.is_completed" size="medium">{{$t('Completed')}}</vs-button>
    </template>
  </div>
</template>

<script>
import {sendRequest} from '../../http/axios/requestHelper.js'
import common  from '@/assets/utils/common'
import vSelect from 'vue-select'
import ShipbluTable from '../../layouts/components/NewShipBluTable.vue'
import ShipbluTr from '../../layouts/components/ShipBluTr.vue'
import ShipbluTh from '../../layouts/components/ShipBluTh.vue'
import ShipbluTd from '../../layouts/components/ShipBluTd.vue'
import ShipbluCard from '../../layouts/components/ShipbluCard.vue'
import ShipbluPagination from '../../layouts/components/ShipbluPagination.vue'
import i18nData from '../../i18n/i18nData.js'

export default {
  data () {
    return {
      localStorage,
      seal: {
        bin: {}
      },
      sealLoading: false,
      trackingsLoading: false,
      maximumItems: process.env.VUE_APP_MAXIMUM_ITEMS_TABLE,
      totalRows: 0,
      currentPage: (this.$route.query.page &&  Number(this.$route.query.page) > 0) ? Number(this.$route.query.page) : 1,
      offset: 0,
      trackings: [],
      sealOrders: [],
      tableLoader: false,
      packages: [],
      warehousesDic: {},
      updateStatus: '',
      sealTrackingEvents: [
        {
          name: 'Sealed',
          value: 'sealed'
        },
        {
          name: 'En Route',
          value: 'en_route'
        },
        {
          name: 'Dropped Off',
          value: 'dropped_off'
        },
        {
          name: 'Missing Packages',
          value: 'missing_packages'
        },
        {
          name: 'Completed',
          value: 'completed'
        }
      ],
      allPackages: [],
      status: '',
      tracking: {}
    }
  },
  watch: {
    currentPage () {
      this.offset = (this.currentPage - 1) * this.maximumItems
      this.loadSealOrders()
    }
  },
  methods: {
    async copied (data) {
      try {
        await navigator.clipboard.writeText(data)
      } catch ($e) {
        this.$vs.notify({
          color:'dark',
          title:i18nData[this.$i18n.locale]['cannot copy!'],
          position: 'bottom-center'
        })
      }
    },
    confirmSeal (status) {
      this.updateStatus = status
      this.$vs.dialog({
        color: 'warning',
        title: 'Confirm',
        text: status === 'sealed' ? 'Are you sure to seal this bag? You can\'t add more packages in this bag!' : 'Are you sure to update this seal with missing packages status?',
        accept: this.updateSealTracking
      })
    },
    updateSealTracking (status) {
      const tracking = {
        seal: this.$route.params.sealId,
        status: status ? status : this.updateStatus,
        warehouse: status || this.updateStatus === 'missing_packages' ? this.seal.bin.to_warehouse : this.status === 'en_route' ? '' :  this.seal.current_warehouse
      }
      sendRequest(false, false, this, 'api/v1/tracking/seals/', 'post', tracking, true, 
        () => {
          this.seal.status = status ? status : this.updateStatus
          this.loadTracking()
        }
      )
    },
    addTracking () {
      this.$validator.validateAll().then(result => {
        if (result) {
          this.updateSealTracking(this.status.value)
        }
      })
    },
    formatWarehouses (warehouses) {
      this.warehouses = warehouses
      this.warehouses.forEach(warehouse => {
        this.warehousesDic[warehouse.id] = warehouse.code
      })
    },
    getStyle (data) {
      return common.getOrderStatusColor(data.status)
    },
    getOrderStatusColor (order) {
      if (order.status) return common.getOrderStatusColor(this.getStatusLabel(order))
    },
    getStatusLabel (order) {
      return common.getStatusLabel(order, this.$store.state.AppActiveUser.userRole)
    },
    loadSeal () {
      this.sealLoading = true
      sendRequest(true, false, this, `api/v1/seals/${this.$route.params.sealId}/`, 'get', null, true, 
        (response) => {
          this.seal = response.data
          this.sealLoading = false
          this.loadTracking()
          this.loadSealOrders()
        }
      )
    },
    loadTracking () {
      this.trackingsLoading = true
      sendRequest(true, false, this, `api/v1/tracking/seals/?seal=${this.$route.params.sealId}`, 'get', null, true, 
        (response) => {
          this.trackings = response.data.results
          this.trackingsLoading = false
          if (this.sealTrackingEvents.filter(item => item.value === 'cancelled').length === 0 && this.trackings.filter(item => item.status === 'en_route').length === 0) {
            this.sealTrackingEvents.push({
              name: 'Cancelled',
              value: 'cancelled'
            })
          }
        }
      )
    },
    loadSealOrders () {
      this.tableLoader = true
      sendRequest(true, false, this, `api/v1/warehousing/seals/${this.$route.params.sealId}/packages/?limit=${this.maximumItems}&offset=${this.offset}`, 'get', null, true, 
        (response) => {
          this.sealOrders = response.data.results
          this.totalRows = response.data.count
          this.allPackages = this.sealOrders.map(item => item.package.id)
          this.packages = []
          this.tableLoader = false
        }
      )
    },
    addMultiplePackages () {
      const packages = this.packages.map(item => item.package)
      sendRequest(false, false, this, `api/v1/warehousing/seals/${this.$route.params.sealId}/packages/`, 'post', {packages}, true, 
        () => {
          this.packages = []
          this.$vs.notify({
            color: 'success',
            title: 'success',
            text: 'The scanned order has been add to master bag successfully.',
            position: 'top-center'
          })
          this.loadSealOrders()
        }
      )
    }
  },
  components: {
    vSelect,
    ShipbluTable,
    ShipbluTr,
    ShipbluTh,
    ShipbluTd,
    ShipbluCard,
    ShipbluPagination
  },
  created () {
    common.manageLoadWarehouses(this.formatWarehouses, this)
    this.loadSeal()
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/vuexy/components/vxTimeline.scss";
.vx-timeline {
  margin-left: 0.5rem;
  padding-left: 40px;
  border-left: 2px dotted #e5e8eb;
}
li {
  position: relative;

  .timeline-icon {
    position: absolute;
    top: 0;
    left: -3.2rem !important;
    border-radius: 50%;
    padding: 0px;
    padding: 0.3rem !important;
    padding-bottom: 0.4rem;
  }
}
.product-order-status, .activity-e-status {
  margin-left: -30px;
}
</style>
